import React from 'react'
import Layout from '../../../components/Layout'
import { Link } from 'gatsby'

const SuccessPage = () => {
  return (
    <Layout>
      <section className='hero is-primary is-bold is-large'>
        <div className='hero-body has-text-centered'>
          <h1 className='title'>Success, I'll get back to you shortly!</h1>
          <h2 className='description has-text-white is-size-3'>
            <Link to='/'>Head back home</Link>
          </h2>
        </div>
      </section>
    </Layout>
  )
}

export default SuccessPage
